<template>
  <div style="padding: 20px">
    <div class="header-search-container">
      <el-radio-group v-model="dimension" @change="handleChangeDimension">
        <el-radio-button label="day">日维度</el-radio-button>
        <el-radio-button label="week">周维度</el-radio-button>
        <el-radio-button label="month">月维度</el-radio-button>
      </el-radio-group>

      <div class="search-date-container">
        <span>选择时间周期：</span>
        <el-date-picker
          v-model="count_at"
          type="date"
          placeholder="选择日期"
          value-format="timestamp"
          @change="handleChangeDate"
        >
        </el-date-picker>
        <el-button style="margin-left: 20px" type="primary" size="small" @click="handlePrev">
          前一周期
        </el-button>
        <el-button type="primary" size="small" @click="handleNext" :disabled="nextButtonDisabled">
          后一周期
        </el-button>
      </div>
    </div>
    <el-table :data="list" border stripe>
      <el-table-column
        v-for="col in columns"
        :prop="col.id"
        :key="col.id"
        :label="col.label"
        :width="col.width"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getBalanceSnapshot } from "@/api/nft-finance.js";
import dayjs from "dayjs";

export default {
  data() {
    const startOfDay = dayjs()
      .subtract(1, "day")
      .startOf("day")
      .unix();
    return {
      columns: [
        { id: "date", label: "快照时间" },
        { id: "channel_id", label: "渠道ID" },
        { id: "channel_name", label: "渠道名称" },
        { id: "channel_remark", label: "渠道账号备注名" },
        { id: "balance", label: "期末余额" },
        { id: "in_order_count", label: "入账笔数" },
        { id: "in_order_amount", label: "入账金额" },
        { id: "out_order_count", label: "出账笔数" },
        { id: "out_order_amount", label: "出账金额" },
      ],
      list: [],
      dimension: "day",
      count_at: startOfDay * 1000,
    };
  },
  computed: {
    nextButtonDisabled() {
      const day = dayjs(this.count_at);
      const newDay = day.add(1, this.dimension);
      return newDay.isAfter(dayjs());
    },
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    async fetchList() {
      try {
        const postData = {
          count_at: Math.floor(this.count_at / 1000),
          dimension: this.dimension,
        };
        const { data } = await getBalanceSnapshot(postData);
        this.list = data.data.list;
      } catch (error) {
        console.log(error);
      }
    },
    handleChangeDimension() {
      if (this.dimension === "day") {
        const day = dayjs()
          .subtract(1, "day")
          .startOf("day")
          .unix();
        this.count_at = day * 1000;
      }
      if (this.dimension === "month") {
        const day = dayjs()
          .startOf("month")
          .unix();
        this.count_at = day * 1000;
      }
      if (this.dimension === "week") {
        const day = dayjs()
          .startOf("week")
          .unix();
        this.count_at = day * 1000;
      }
      this.fetchList();
    },
    handleChangeDate() {
      this.fetchList();
    },
    handlePrev() {
      const day = dayjs(this.count_at);
      this.count_at = day.subtract(1, this.dimension).unix() * 1000;
      this.fetchList();
    },
    handleNext() {
      const day = dayjs(this.count_at);
      this.count_at = day.add(1, this.dimension).unix() * 1000;
      this.fetchList();
    },
  },
};
</script>

<style lang="less">
.header-search-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;

  .search-date-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
  }
}
</style>
