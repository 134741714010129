import config from "@/configs/index";
import axios from "axios";

// 获取财务统计报表
export function getFinanceStats(postData) {
  return axios({
    url: config.url + "/phoenix/admin_nft_finance/nft_intl_get_finance_stats",
    method: "post",
    data: postData,
  });
}

// 获取渠道余额快照
export function getBalanceSnapshot(postData) {
  return axios({
    url: config.url + "/v1/transact/fiat/statistics/count_fiat_and_balance",
    method: "post",
    data: postData,
  });
}

// 获取交易详情列表
export function getTransactionList(postData) {
  return axios({
    url: config.url + "/phoenix/admin_nft_finance/nft_intl_get_transaction_detail",
    method: "post",
    data: postData,
  });
}

export function exportBalanceSnapshot(postData) {
  return axios({
    url: config.url + "/phoenix/admin_nft_finance/nft_intl_export_transaction_detail",
    method: "post",
    data: postData,
  });
}
